import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Layout from '../layouts/Default';
import Section from '../components/Section';
import { BlogList } from '../components/BlogRoll/BlogRoll';

function TagRoute(props) {
  const { tag } = props.pageContext;
  const { title } = props.data.site.siteMetadata;
  const { totalCount } = props.data.allMdx;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with “${tag}”`;

  return (
    <Layout>
      <Section
        sx={(theme) => ({
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        })}
      >
        <Helmet title={`${tag} | ${title}`} />
        <div className="content">
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
              style={{ marginBottom: '6rem' }}
            >
              <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
              <p>
                <Link to="/tags/">Browse all tags</Link>
              </p>
              <br />
              <BlogList data={props.data} />
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

TagRoute.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default TagRoute;

export const tagPageQuery = graphql`query TagPage($tag: String) {
  site {
    siteMetadata {
      title
    }
  }
  allMdx(
    limit: 1000
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {tags: {in: [$tag]}}}
  ) {
    totalCount
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          templateKey
          date(formatString: "MMMM DD, YYYY")
          author {
            id
            name
            bio
            twitter
          }
          featuredpost
          featuredimage {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`;
